<template>
  <div id="app">
    <Header v-if="showHeader" />
    <div class="app-content">
      <router-view />
    </div>
    <Widget v-if="apmInit" />
    <Footer v-if="showFooter" />
    <ImagePopup />
    <Feedback />
    <Signup />
    <Recover />
    <Authorization />
    <SuccessFeedback />
    <CommonError />
    <CommonSuccess />
    <CodeRegistration />
    <TemporarilyUnavailable />
  </div>
</template>
<script>
import ImagePopup from "./components/modals/imagePopup.vue";
import Widget from "./components/Widget.vue";
import Recover from "./components/modals/recover.vue";
import Authorization from "./components/modals/authorization.vue";
import CodeRegistration from "./components/modals/codeRegistration.vue";
import CommonSuccess from "./components/modals/commonSuccess.vue";
import Signup from "./components/modals/signup.vue";
import Header from "@/components/AppHeader.vue";
import Footer from "@/components/AppFooter.vue";
import Feedback from "@/components/modals/feedback.vue";
import TemporarilyUnavailable from "@/components/modals/temporarilyUnavailable.vue";

import SuccessFeedback from "@/components/modals/successFeedback.vue";

import CommonError from "@/components/modals/commonError.vue";

export default {
  components: {
    Widget,
    Header,
    Footer,
    Feedback,
    SuccessFeedback,
    CommonError,

    TemporarilyUnavailable,
    Signup,
    CommonSuccess,
    CodeRegistration,
    Authorization,
    Recover,
    ImagePopup,
  },
  data: function () {
    return {
      showHeader: true,
      showFooter: true,
      apmInit: false,
      network: null,
    };
  },
  methods: {
    checkAuth(t) {
      if (this.$store.getters.token) {
        if (!this.$store.getters.user) {
          this.$store.dispatch("GetProfile").then((response) => {
            const { result, data } = response;
            if (!result) {
              if (this.$route.path !== "/") {
                // this.$router.push("/");
              }
            } else {
              window.dataLayer?.push({
                event: "authentication",
                UserID: data?.uuid,
              });
              setTimeout(() => {
                if (window.promoTools)
                  window.promoTools.checkGrabberWidget.settings = {
                    userUuid: this.$store.getters?.user?.uuid,
                  };
              }, 2000);
            }
          });
        }
      }
    },
    gtmEventsOnPages(page) {
      const widgetButton = document.querySelector(".info-button__button");
      const widgetScan = document.querySelector(".scan-button__button");
      widgetButton.onclick = function () {
        window.dataLayer?.push({
          event: "gaEvent",
          category: "Check_reg_main_form_SC2_CB",
          action: "Check_reg_main_form_SC2_CB",
          label: page,
        });
      };
      widgetScan.onclick = function () {
        window.dataLayer?.push({
          event: "gaEvent",
          category: "Check_reg_main_form_SC2_CB",
          action: "Check_reg_main_form_SC2_CB",
          label: page,
        });
      };
    },
    initWidget() {
      const t = this;
      const BASE_API = `${process.env.VUE_APP_URL}api-perekrestok`;

      if (window.promoTools)
        window.promoTools.checkGrabberWidget.settings = {
          skin: "default",
          events: {
            onSentSuccess(res) {
              t.$store.dispatch("GetProfile");
            },
            onSentError(res) {
              this.gtmEvent('', 'check', 'check_download_error', 'qr');
            },
            onUseQr() {
              this.gtmEvent('', 'check', 'check_download_success', 'qr');
            },
            onUsePhotos() {
              this.gtmEvent('', 'check', 'check_download_success', 'photo');
            },
            onUseManual() {
              this.gtmEvent('', 'check', 'check_download_success', 'manually');
              console.log("onUseManual");
              setTimeout(() => {
                const checkInfo = document.getElementById("check-info");
                checkInfo.addEventListener("click", function () {
                  t.$modal.show("image", {
                    src: require("@/assets/images/instruction.png"),
                  });
                });
              }, 2000);
            },
          },
          api: BASE_API,
          method: "/check",
          userUuid: this.$store.getters?.user?.uuid,
          i18n: {
            qr: {
              title: "Сканирование",
              subTitle: "Наведите камеру на QR-код",
              btnQr: "Сканировать QR-код чека",
              btnManual: "Ввести данные вручную",
              btnPhotos: "Загрузить фото чека",
              cameraError: {
                title:
                  "<strong>Ошибка</strong>Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства. <br>Разрешите браузеру обращаться к&nbsp;камере или введите данные с&nbsp;чека вручную.",
              },
            },
            photos: {
              title: "Загрузка фото",
              subTitle: "Добавьте фото чека",
              btnPhotos: "Отправить",
            },
            manual: {
              title: "Ручной ввод",
              subTitle: `Введите данные с чека <span id='check-info' class="check-info"><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.21319 3.06901C1.52823 4.56104 0.243299 5.72328 0.172847 8.03289C0.0877187 10.8339 1.11726 13.8736 3.21319 15.9751C4.90549 17.6693 7.03356 18.9534 9.29387 18.9534C9.834 18.9534 10.7762 19.0582 11.3208 18.9534C14.2254 18.3941 16.1891 15.8126 17.4014 12.9968C18.8868 9.54489 18.2146 5.16802 15.3746 2.07624C12.0692 -1.52322 6.62274 0.0480628 3.21319 3.06901Z" fill="#F3410E"/>
<path d="M9.16846 8C8.72839 8.03117 8.1876 8.59031 8.16846 9V14C8.14933 14.4097 8.68838 14.9837 9.16846 15C9.15629 15 9.18064 15 9.16846 15C9.63289 15 10.1511 14.3993 10.1685 14V9C10.1876 8.59031 9.64854 8.01633 9.16846 8Z" fill="white"/>
<path d="M9.16846 7C9.72046 7 10.1685 6.40537 10.1685 6C10.1685 5.59463 9.72046 5 9.16846 5C8.61646 5 8.16846 5.59463 8.16846 6C8.16846 6.40683 8.61646 7 9.16846 7Z" fill="white"/>
</svg>
</span>`,
              btnManual: "Отправить",
            },
            success: {
              title: "Благодарим!",
              subTitle: "Ваш чек принят в обработку.",
            },
            pending: {
              title: "Подождите!",
              subTitle: "Идет отправка чека",
            },
            fail: {
              title: "Не удалось отправить чек!",
            },
          },
        };
    },
  },
  mounted() {
    window.addEventListener(
      "load",
      () => {
        this.initWidget();
      },
      false
    );
    const t = this;
    const params = this.$route.query;
    if (params.email === "0") {
      let socId = params.identity_vk || params.identity_ok || "";

      console.log(socId);

      if (socId.length < 1) {
        this.$modal.show("common_error", {
          title: "Что-то пошло не так!",
        });
        this.$router.push("/");
      } else {
        this.$store.commit("SIGNUP_SOC_ID", socId);
        this.$modal.show("soc_signup");
        this.$router.push({
          name: "Home",
        });
      }
    } else if (params.first === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);
      this.$store.dispatch("GetProfile").then((r) => {
        this.$modal.show("soc_signup-finish", {
          userName: r.data.profile.name,
        });
        this.$router.push({
          name: "Home",
        });
      });
    } else if (params.result === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);
      this.$store.dispatch("GetProfile").then(() => {
        this.$router.push({
          to: "/",
        });
      });
    } else if (params["reset-password"] === "1") {
      console.log("dgf");
      this.$router.push({
        name: "NewPassword",
        query: {
          token: params.token,
        },
      });
    }

    this.checkAuth(t);
  },
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || "";
      },
      immediate: true,
    },
    "$route.name": function () {},
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
  min-width: 320px;
}
.e-root {
  padding-left: rem(20px) !important;
  @media (min-width: $sm) {
    padding-bottom: 67px !important;
  }
}
.e-root__buttons {
  justify-content: flex-start !important;
  @media (max-width: $sm) {
    transform: scale(0.7);
    transform-origin: left bottom;
  }
  @media (min-width: $lg) {
    max-width: 95%;
    margin: auto;
  }
}
.scan-button__wrapper {
  @media (max-width: $sm) {
    margin-left: 3px !important;
  }
}
</style>
