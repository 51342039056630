var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form-page-form__block"},[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
    },attrs:{"label":"Email","placeholder":"example@yandex.ru","type":"email","field":_vm.email,"autocomplete":"username","error":(!_vm.$v.email.required && _vm.$v.email.$error
        ? 'Обязательное поле'
        : !_vm.$v.email.email && _vm.$v.email.$error
        ? 'Некорректный e-mail'
        : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.password) || this.errorMessage.password,
    },attrs:{"label":"Введи пароль","type":"password","field":_vm.password,"autocomplete":"current-password","error":(!_vm.$v.password.required && _vm.$v.password.$error
        ? 'Обязательное поле'
        : !_vm.$v.password.minLength && _vm.$v.password.$error
        ? 'Минимум ' + _vm.$v.password.$params.minLength.min + ' символов'
        : '') || this.errorMessage.password},on:{"input":function($event){_vm.password = $event}}}),_c('div',{staticClass:"form-page-form__row"},[_c('router-link',{staticClass:"form-page-form__forget-link _undeline-link",attrs:{"to":{ name: 'Recover' }},on:{"click":function($event){$event.preventDefault();return _vm.switchToRecover()}}},[_vm._v(" Забыли пароль? ")])],1),_c('div',{staticClass:"form-page-form__footer"},[_c('button',{staticClass:"btn btn--primary btn--icon",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Войти "),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})]),_c('div',{staticClass:"form-page__another-option"},[_c('p',[_vm._v(" Нет профиля? "),_c('router-link',{staticClass:"_undeline-link",attrs:{"to":{ name: 'Signup' }}},[_vm._v("Зарегистрируйся")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }