<template>
  <section class="hero section">
    <div class="container">
      <div class="hero__row">
        <div class="hero__content">
          <h1 class="hero__title">
            Участвуйте в&nbsp;акции и&nbsp;выигрывайте ценные призы!
          </h1>
          <div class="hero__text">
            C&nbsp;1&nbsp;мая по&nbsp;30&nbsp;июня 2024
          </div>
          <button @click="toCheckUpload({
                site_section: 'main',
                action: 'check_download',
                eventContext: 'first_screen',
              })" class="btn btn--primary btn--lg">
            Загрузить чек
          </button>
        </div>
        <div class="hero__images">
          <div class="hero-bg-elements">
            <div class="hero-bg-elements__item">
              <svg
                width="43"
                height="43"
                viewBox="0 0 43 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3329 0.206998L27.7276 12.1905L42.949 16.6784L30.9627 27.0653L26.4751 42.2754L16.0801 30.2919L0.863768 25.8054L12.8501 15.4184L17.3329 0.206998Z"
                  fill="#EFA800"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.6015 0.776241L22.9219 11.898L31.7189 19.4741L20.5973 22.7923L13.0224 31.5848L9.70186 20.463L0.907796 12.8893L12.0294 9.57116L19.6015 0.776241Z"
                  fill="#71C1DF"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="43"
                height="43"
                viewBox="0 0 43 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3329 0.206998L27.7276 12.1905L42.949 16.6784L30.9627 27.0653L26.4751 42.2754L16.0801 30.2919L0.863768 25.8054L12.8501 15.4184L17.3329 0.206998Z"
                  fill="#EFA800"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item _no-animate">
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.1629 8.4125C34.8727 12.9057 33.4289 18.1875 31.417 22.9461C29.4033 27.706 26.8196 31.944 25.24 34.3481C25.159 34.4707 25.04 34.5537 24.9094 34.5946C24.7769 34.6368 24.6329 34.6369 24.4948 34.5843C19.1455 32.4814 5.42606 27.6278 1.38187 19.4732C0.38433 17.464 -0.0145532 15.4919 0.197544 13.6154L0.204395 13.5566C0.592751 9.81613 2.3929 7.14302 6.00912 5.50707C9.29272 4.02015 14.0991 4.09435 17.3263 7.65764C17.9653 2.96443 20.0861 1.32404 23.5298 0.415863C25.7698 -0.177212 29.2608 0.11174 31.7262 2.89197L31.7533 2.92828C33.3023 4.65354 33.9181 6.85962 34.1629 8.4125Z"
                  fill="#D00015"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
                  fill="#D20C1C"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
                  fill="#D20C1C"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
                  fill="#D20C1C"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M0.354605 4.03071C1.20326 1.11284 4.25586 -0.563297 7.17103 0.283674C10.0885 1.13131 11.7665 4.18185 10.9178 7.09973C10.0691 10.0176 7.01649 11.6936 4.09901 10.846C1.18384 9.99903 -0.494049 6.94858 0.354605 4.03071Z"
                  fill="#61BADB"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
                  fill="#EFA800"
                />
              </svg>
            </div>
            <div class="hero-bg-elements__item">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
                  fill="#EFA800"
                />
              </svg>
            </div>
          </div>

          <Sun />

          <img
            src="../assets/images/hero/country.png"
            alt=""
            class="hero__img hero__img--1"
          />
          <img
            src="../assets/images/hero/egg.png"
            alt=""
            class="hero__img hero__img--2"
          />
          <img
            src="../assets/images/hero/choco.png"
            alt=""
            class="hero__img hero__img--3"
          />
          <img
            src="../assets/images/hero/milk-bueno.png"
            alt=""
            class="hero__img hero__img--4"
          />
        </div>
      </div>
      <div class="hero__note">
        Реклама. Организатор акции ООО &laquo;Фэмели&raquo;. С&nbsp;01.05.2024
        по&nbsp;30.06.2024 покупайте любые продукты Kinder<sup class="reg"
          >&reg;</sup
        >
        на&nbsp;сумму от&nbsp;150 рублей в&nbsp;одном чеке и&nbsp;участвуйте
        в&nbsp;розыгрыше призов. Общий срок акции: с&nbsp;01.05.2024
        по&nbsp;30.09.2024 (включая срок вручения призов). Есть ограничения.
        Информация об&nbsp;организаторе акции, правилах ее&nbsp;проведения,
        количестве призов, сроках, месте и&nbsp;порядке их&nbsp;получения
        размещена на&nbsp;www.perekrestok.promo-x5.ru. Организатор вправе
        приостановить, прекратить досрочно проведение акции или изменить другие
        ее&nbsp;условия.
      </div>
    </div>
  </section>
</template>

<script>
import Sun from "../components/AppSun.vue";
export default {
  components: { Sun },
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {
    // this.toCheckUpload()
  },
};
</script>
<style lang="scss" scoped>
.hero {
  position: relative;
  // height: calc(var(--vh, 1vh) * 100);
  padding-top: 0;
  padding-bottom: rem(60px);
  z-index: 9;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: linear-gradient(to bottom, #ddeef2, #c3f1ff);
  overflow: hidden;
  .container {
    position: relative;
    padding-top: rem(130px);
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: rem(280px);
    bottom: vh(0px);
    left: 0;
    background: url("../assets/images/waves.png") no-repeat left top;
    background-size: 180%;
  }
  &-bg-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
    &__item {
      position: absolute;
      display: block;
      transform: scale(0.7);
      animation: rotareStar 4s linear infinite forwards normal;
      svg {
        animation: blinkSmooth 2s infinite forwards alternate;
      }
      &._no-animate {
        animation: none;
      }
      &:nth-child(1) {
        top: -7%;
        left: 94%;
      }
      &:nth-child(2) {
        top: 29%;
        left: 10%;
      }
      &:nth-child(3) {
        top: 82%;
        left: 52%;
      }
      &:nth-child(4) {
        top: 5%;
        left: 32%;
      }
      &:nth-child(5) {
        top: 90%;
        left: 20%;
        @media (min-width: $md) {
          left: 33%;
        }
      }
      &:nth-child(6) {
        top: 7%;
        left: 15%;
      }
      &:nth-child(7) {
        top: -12%;
        left: 60%;
      }
      &:nth-child(8) {
        top: -5%;
        left: 13%;
        display: none;
        @media (min-width: $md) {
          display: block;
        }
      }
      &:nth-child(9) {
        top: 80%;
        left: 12%;
      }
      &:nth-child(10) {
        top: 52%;
        left: 102%;
      }
      &:nth-child(11) {
        top: 7%;
        left: 95%;
      }
      &:nth-child(2n) {
        animation-delay: 0.5s;
        svg {
          animation-delay: 0.5s;
        }
      }
      &:nth-child(3n) {
        animation-delay: 0.7s;
        svg {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(4n) {
        animation-delay: 1s;
        svg {
          animation-delay: 1s;
        }
      }
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
  &__row {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: auto;
    flex-direction: column;
  }
  &__col {
    position: relative;
  }
  &__title {
    margin: 0 auto rem(20px);
    font-size: rem(36px);
    text-align: left;
    line-height: 1.07;
    span {
      display: block;
      font-size: rem(56px);
      &:last-child {
        font-size: rem(54px);
      }
    }
  }
  &__text {
    margin-bottom: rem(20px);
    font-family: $font-reg;
    font-size: rem(16px);
    line-height: 1;
  }

  &__img {
    &-mobile {
      width: calc(100% + #{rem(40px)});
      margin-bottom: rem(30px);
      max-width: none;
    }
  }
  .btn {
    order: 1;
    width: auto;
    margin-bottom: auto;
  }

  &__images {
    background: url("../assets/images/hero/pedestal.png") no-repeat center
      bottom;
    background-size: contain;
    position: relative;
    width: 100%;
    height: 100vw;
    background-size: 80%;
    background-position: left rem(16px) bottom rem(100px);
    .sun {
      position: absolute;
      bottom: rem(40px);
      right: rem(-10px);
      width: rem(145px);
    }
  }
  &__img {
    position: absolute;
    max-width: none;
    &--1 {
      bottom: rem(45px);
      left: rem(-35px);
      width: rem(113px);
    }
    &--2 {
      bottom: rem(130px);
      left: rem(35px);
      width: rem(105px);
    }
    &--3 {
      bottom: rem(150px);
      left: rem(100px);
      width: rem(117px);
    }

    &--4 {
      bottom: rem(180px);
      left: rem(120px);
      width: rem(240px);
    }
  }
  &__note {
    margin-top: rem(25px);
    font-size: rem(10px);
    line-height: rem(15px);
    font-family: $font-reg;
    letter-spacing: 0.065em;
  }
  @media (min-width: $sm) {
    padding-top: 0;
    height: calc(var(--vh, 1vh) * 111);
    padding-bottom: 0;
    &::before {
      height: vh(180px);
      bottom: vh(0px);
      background: url("../assets/images/waves.png") no-repeat center top;
      background-size: 100%;
    }
    &-bg-elements {
      &__item {
        transform: scale(1);
      }
    }
    .container {
      padding-top: 0;
      display: flex;
      align-items: center;
    }
    &__title {
      font-size: rem(48px);
      margin-bottom: rem(6px);
    }
    &__subtitle {
      font-size: rem(22px);
    }
    &__note {
      position: absolute;
      bottom: 0;
      font-size: rem(12px);
      line-height: rem(15px);
    }
    &__row {
      margin: auto;
    }
    &__content {
      padding-top: rem(113px);
    }
    &__text {
      width: 80%;
    }
    &-nav {
      &-title {
        margin-bottom: rem(22px);
        font-size: rem(34px);
      }
      &__item {
        padding: rem(23px) 0;
        width: rem(152px);
      }
    }
  }
  @media (min-width: $md) {
    .container {
      padding-top: vh(163px);
      flex: auto;
      align-items: flex-start;
    }
    &__row {
      max-width: 100%;
      flex-direction: row;
      align-items: flex-start;
    }
    &__title {
      margin: 0;
      margin-bottom: rem(18px);
      text-align: left;
      font-size: rem(48px);
      line-height: rem(53px);
      letter-spacing: -0.01em;
      span {
        letter-spacing: -0.01em;
        font-size: vh(104px);
        &:last-child {
          font-size: vh(104px);
        }
      }
    }
    &__subtitle {
      margin-bottom: rem(64px);
      font-size: vh(26px);
      line-height: vh(30px);
    }
    &__text {
      width: 100%;
      margin: 0 0 rem(30px);
      text-align: left;
      font-size: rem(24px);
      line-height: rem(29px);
      letter-spacing: -0.01em;
      opacity: 0.7;
    }
    &__content {
      max-width: 40%;
      align-items: flex-start;
      padding-top: 0;
    }

    &__images {
      position: relative;
      width: 60%;
      height: 77%;
      background-size: vh(496px);
      background-position: left vh(106px) bottom vh(110px);
      .sun {
        position: absolute;
        bottom: vh(20px);
        right: vh(-15px);
        width: rem(267px);
      }
    }
    &__img {
      position: absolute;
      max-width: none;
      &--1 {
        bottom: vh(2px);
        left: vh(-22px);
        width: vh(213px);
      }
      &--2 {
        bottom: vh(160px);
        left: vh(108px);
        width: vh(218px);
      }
      &--3 {
        bottom: vh(193px);
        left: vh(230px);
        width: vh(247px);
      }

      &--4 {
        bottom: vh(280px);
        left: vh(290px);
        width: vh(440px);
      }
    }

    &__btn {
      flex-shrink: 0;
      letter-spacing: 0.04em;
      img {
        width: vh(60px);
        margin-left: vh(9px);
        flex-shrink: 0;
      }
      &--1 {
        margin-bottom: vh(27px);
        margin-left: vh(5px);
        padding: vh(19px) vh(30px) vh(19px) vh(40px);
        transform: rotate(2deg);
      }
      &--2 {
        margin-left: vh(5px);
        padding: vh(19px) vh(30px);
        transform: rotate(-7deg);
      }
    }
    .btn {
      margin: 0;
      order: 0;
      padding: 0 rem(35px);
    }
  }
  @media (max-width: $xlg) {
  }
}
</style>
