<template>
  <form
    class="form-page-form__block"
    method="post"
    autocomplete="off"
    v-if="user"
  >
    <inputText
      label="Имя"
      :field="name"
      @input="name = $event"
      :class="{
        error: validationStatus($v.name) || errorMessage.name,
      }"
      :error="
        (!$v.name.required && $v.name.$error
          ? 'Обязательное поле'
          : !$v.name.rus && $v.name.$error
          ? 'Введите текст на русском языке'
          : '') || errorMessage.name
      "
    />
    <inputText
      label="Номер карты лояльности"
      placeholder="1234 4567 8910 1112"
      :mask="'#### #### #### ####'"
      :field="card"
      name="card"
      @input="card = $event"
      autocomplete="off"
      :class="{
        error: validationStatus($v.card) || errorMessage.card,
      }"
      :error="
        (!$v.card.minLength && $v.card.$error
          ? 'Минимум ' + $v.card.$params.minLength.min + ' символов'
          : '') || errorMessage.card
      "
    />
    <inputText
      label="Текущий пароль"
      type="password"
      :field="password"
      autocomplete="none"
      :class="{
        error: validationStatus($v.password) || errorMessage.password,
      }"
      :error="
        (!$v.password.required && $v.password.$error
          ? 'Обязательное поле'
          : '') || errorMessage.password
      "
      @input="password = $event"
    />
    <inputText
      label="Новый пароль"
      type="password"
      :field="password_new"
      autocomplete="new-password"
      :class="{
        error: validationStatus($v.password_new) || errorMessage.password_new,
      }"
      :error="
        (!$v.password_new.required && $v.password_new.$error
          ? 'Обязательное поле'
          : !$v.password_new.minLength && $v.password_new.$error
          ? 'Минимум ' + $v.password_new.$params.minLength.min + ' символов'
          : '') || errorMessage.password_new
      "
      @input="password_new = $event"
    />
    <inputText
      label="Новый пароль еще раз"
      type="password"
      autocomplete="new-password"
      :field="password_rep"
      :class="{
        error: validationStatus($v.password_rep) || errorMessage.password_rep,
      }"
      :error="
        (!$v.password_rep.required && $v.password_rep.$error
          ? 'Обязательное поле'
          : !$v.password_rep.sameAs && $v.password_rep.$error
          ? 'Пароли должны совпадать'
          : '') || errorMessage.password_new
      "
      @input="password_rep = $event"
    />

    <div class="form-page-form__footer">
      <button
        type="button"
        class="btn btn--primary btn--icon"
        @click="updateProfile()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Сохранить изменения
        <img src="../../assets/img/spinner.svg" class="loader" alt="" />
      </button>
      <div class="form-page__another-option">
        <p>
          <span @click="logout()" class="_undeline-link"
            >Выйти из аккаунта</span
          >
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "UserInfo",
  data() {
    return {
      ready: false,
      password: null,
      password_rep: null,
      password_new: null,
      checkbox: true,
      errorMessage: {
        name: null,
        card: null,
        password: null,
        password_new: null,
        password_rep: null,
        checkbox: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    name: { rus },
    card: { minLength: minLength(16) },
    password_rep: {
      sameAs: sameAs(function () {
        return this.password_new;
      }),
    },
    password_new: { minLength: minLength(4) },
    password: { minLength: minLength(4) },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        namr: null,
        card: null,
        password: null,
        password_new: null,
        password_rep: null,
      };
    },

    close() {
      this.$store.commit("SHOW_POPUP_CONTENT", false);
    },
    switchToRecover() {
      this.$modal.hide("authorization");
      this.$modal.show("recover");
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    pushTo(link) {
      window.open(link, "_blank").focus();
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    updateProfile() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const data = {
          name: this.name,
          card: this.card,
          passwordNew: this.password_new,
          passwordNewRepeat: this.password_rep,
        };
        this.$store
          .dispatch("UpdateProfile", data)
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email;
                this.gtmEvent('email', 'profile', 'profile_error');
                fieldsError = true;
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
                this.gtmEvent('login', 'profile', 'profile_error');
              }

              if (r.error && r.error.name) {
                [this.errorMessage.name] = r.error.name;
                fieldsError = true;
                this.gtmEvent('name', 'profile', 'profile_error');
              }
              if (r.error && r.error.card) {
                [this.errorMessage.card] = r.error.card;
                fieldsError = true;
                this.gtmEvent('card', 'profile', 'profile_error');
              }
              if (r.error && r.error.old_password) {
                [this.errorMessage.password] = r.error.old_password;
                fieldsError = true;
                this.gtmEvent('old_password', 'profile', 'profile_error');
              }
              if (r.error && r.error.passwordNew) {
                [this.errorMessage.password_new] = r.error.passwordNew;
                fieldsError = true;
                this.gtmEvent('passwordNew', 'profile', 'profile_error');
              }
              if (r.error && r.error.passwordNewRepeat) {
                [this.errorMessage.password_rep] = r.error.passwordNewRepeat;
                fieldsError = true;
                this.gtmEvent('passwordNewRepeat', 'profile', 'profile_error');
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
                this.gtmEvent(r.error, 'profile', 'profile_error');
              }
            } else {
              this.gtmEvent('', 'profile', 'change_info');
              this.submitStatus = null;
              this.formIndex = this.formIndex += 1;
              this.$store.commit("SET_TOKEN", r.data.auth_key);
              this.$modal.show("common_success", {
                title: "Спасибо!",
                text: "Профиль успешно обновлен.",
              });
              this.getUserData();
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
    showSignUpModal() {
      this.$modal.hide("authorization");
      this.$modal.show("signup");
      // this.$modal.show("temporarily_unavailable");
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    name: {
      get() {
        return this.user.name;
      },
      set(val) {
        console.log(val);
        return val;
      },
    },
    email: {
      get() {
        return this.user.email;
      },
      set(val) {
        return val;
      },
    },
    card: {
      get() {
        return this.user.card;
      },
      set(val) {
        this.user.card = val;
      },
    },
  },
  mounted() {},
  components: {
    inputText,
  },
};
</script>

<style lang="scss" scoped>
.authorization {
  h3 {
    text-align: center;
    margin-top: rem(8px);
  }

  &__signin {
    width: 100%;
    margin: 0 auto;
    &-fields {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    &-footer {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
    }
    .forget-link {
      white-space: nowrap;
      font-size: rem(14px);
      color: rgba(32, 0, 130, 1);
      text-align: right;
    }

    .forget-link:hover {
      text-decoration: none;
    }
  }
  &__checkbox {
    white-space: nowrap;
  }
  &__row {
    width: 100%;
    display: flex;
    margin-top: rem(10px);
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20px);
  }

  @media (min-width: $md) {
    &__checkbox {
    }
    &__signin {
      &-fields {
      }
      .forget-link {
        margin: 0 0 0 auto;
        font-size: rem(18px);
        line-height: rem(29px);
      }
      &-footer {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        .btn {
          min-width: auto;
          margin: 0;
          &:not(:last-of-type) {
            margin-right: rem(24px);
          }
        }
      }
    }
    &__row {
      flex-direction: row;
      align-items: center;
      margin-bottom: rem(15px);
      margin-top: rem(10px);
    }
  }
}
</style>
